* {
    box-sizing: border-box;
}

.button {
    height: 100px;
    width: 200px;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
    display: flex;
    justify-content: center;
}

body {
    height: 100vh;
    color: #020000;
}

.container {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 800px;
}

.menu {
    max-width: 800px;
    height: 100vh;
    overflow: hidden;
    background: #fff;
    cursor: -webkit-grab;
    cursor: grab;
}

.menu.is-dragging {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.menu ul {
    counter-reset: count;
}

.menu--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    counter-increment: count;
    z-index: 1;
    top: 0;
    left: 0;
    max-width: 800px;
    font-size: 50px;
    line-height: 1.2;
    text-align: center;
}

.menu--item div {
    color: #020000;
    text-decoration: none;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    font-size: 50px;
    -webkit-appearance: none;
    background: none;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    color: #020000;
    font-family: "Verdana", serif;
}

.question-container {
    display: flex;
    min-height: 300px;
    margin: 0 10px;
    cursor: pointer;
}

.question {
    flex-direction: column;

    .title {
    }

    .description {
        flex-direction: column;
        font-size: 30px;
        p {
            margin-bottom: 26px;
        }
        ul {
            font-size: 26px;
            margin: 0;
            text-align: left;
            li {
                margin-bottom: 20px;
            }
        }
        .star {
            font-size: 26px;
            margin-top: 6px;
        }
    }
}

.question-shake {
    animation: wiggle 2s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
    0%, 7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%, 100% {
        transform: rotateZ(0);
    }
}